<template>
  <div class="container center_div">
    <form v-on:submit.prevent="register()" id="form">
      <h3>Registar novo utilizador</h3>
      <div class="form-group">
        <label for="email">Email*</label>
        <input
          type="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Insira email do utilizador"
          required
          v-model="email"
        />
      </div>
      <div class="form-group">
        <label for="username">Nome*</label>
        <input
          type="text"
          class="form-control"
          id="username"
          aria-describedby="emailHelp"
          placeholder="Insira o nome do utilizador"
          required
          v-model="username"
        />
      </div>
      <div class="form-group">
        <label for="password">Password*</label>
        <input type="password" class="form-control" id="password" placeholder="Password" required v-model="password" />
      </div>
      <div class="form-group">
        <label for="confPassword">Confirmar Password*</label>
        <input
          type="password"
          class="form-control"
          id="confPassword"
          placeholder="Confirmar Password"
          required
          v-model="confPassword"
        />
      </div>
      <small>*Campos obrigatórios</small> <br />
      <button type="submit" class="btn btn-primary float-right">Adicionar</button>
    </form>
    {{ email }}
    {{ password }}
    {{ confPassword }}
    {{ username }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      email: '',
      username: '',
      password: '',
      confPassword: '',
    };
  },
  methods: {
    async register() {
      try {
        await this.$store.dispatch('postUser', {
          email: this.email,
          username: this.username,
          password: this.password,
          confPassword: this.confPassword,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: this.getRegisterMessage,
          showConfirmButton: false,
          timer: 1500,
        });
        this.email = '';
        this.username = '';
        this.password = '';
        this.confPassword = '';
      } catch (err) {
        console.log(err);
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: err,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getRegisterMessage']),
  },
};
</script>
<style scoped>
center_div {
  margin: 0 auto;
  width: 80%; /* value of your choice which suits your alignment */
}
h3 {
  margin-top: 10px;
  color: rgb(255, 147, 147);
}
.btn {
  right: 0px;
  margin-top: 10px;
}
.form-group {
  margin-top: 10px;
}
button {
  margin-top: 20px;
  float: right;
  background-color: rgb(104, 155, 75);
  border: none;
}
button:hover {
  background-color: rgb(74, 106, 192);
}
</style>
