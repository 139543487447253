<template>
  <div class="box">
    <a
      ><router-link to="/users"><b-icon-arrow-left-circle /> Voltar</router-link></a
    >
    <RegisterForm />
  </div>
</template>

<script>
import RegisterForm from "@/components/RegisterForm.vue";
export default {
  components: {
    RegisterForm,
  },
};
</script>
<style scoped>
a {
  margin: 25px;
  padding-top: 20px;
  text-decoration: none;
  color: black;
  font-size: 20px;
}
a:hover {
  color: gray;
}
.box {
  margin-top: 30px;
}
</style>
